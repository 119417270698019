import { get } from "svelte/store";
import {
    languageData,
    localizationFunctions,
    initialized,
    currentLanguage
} from "./store.js";

/**
 * Load languages
 * @param {{Object}[]} langs
 * @param {string} langs[].name - short name of language, e.g. 'en', 'sv'
 * @param {Object} langs[].data - JSON object
 * @returns {void}
 */
export function load(langs) {
    if (langs.length > 0) {
        const newLanguageData = {};

        for (let i = 0; i < langs.length; i++)
            newLanguageData[langs[i].name] = langs[i].data;

        languageData.set(newLanguageData);
        initialized.set(true);
        currentLanguage.set(langs[0].name);
    } else throw "Attempted to load empty list of languages";
}

export function locale(lang) {
    if (lang !== undefined) {
        if (initialized && Object.keys(get(languageData)).includes(lang)) {
            currentLanguage.set(lang);
            localize();
        } else throw "Locale '" + lang + "' does not exist";
    }

    return currentLanguage;
}

/**
 * takes a key, use "/" to traverse into a json object, returns either a string, array, or JSON object
 * @param {string} key
 * @returns {{string} | {Object} | {Object[]}}
 */
export function translate(key) {
    if (!initialized) throw "No languages has been loaded";

    const keyParts = key.split("/");
    let ret = get(languageData)[get(currentLanguage)];
    for (let i = 0; i < keyParts.length; i++) {
        if (keyParts[i].includes("[") && keyParts[i].includes("]")) {
            const arrayWithIndex = keyParts[i].split("[");
            ret =
                ret[arrayWithIndex[0]][
                    arrayWithIndex[1].substring(0, arrayWithIndex[1].length - 1)
                ];
        } else ret = ret[keyParts[i]];
    }

    if (ret === undefined)
        throw (
            "Language '" +
            currentLanguage +
            "' is missing the key '" +
            key +
            "'."
        );

    return ret;
}

export function subscribe(func) {
    if (!get(localizationFunctions).includes(func))
        localizationFunctions.update((functions) => {
            functions.push(func);
            return functions;
        });
}

export function localize() {
    const funcs = get(localizationFunctions);
    for (let i = 0; i < funcs.length; i++) funcs[i]();
}
