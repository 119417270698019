<script>
    import { subscribe, translate } from "../localization/Localize";
    import Divider from "../components/Divider.svelte";

    let send = "";
    let sent = "";
    let sending = "";
    let failed = "";

    subscribe(() => {
        document.getElementById("contactHeader").innerText =
            translate("contact/header");
        document.getElementById("contactBody").innerText =
            translate("contact/body");
        document.getElementById("contactName").placeholder = translate(
            "contact/placeholders/name"
        );
        document.getElementById("contactEmail").placeholder = translate(
            "contact/placeholders/email"
        );
        document.getElementById("contactSubject").placeholder = translate(
            "contact/placeholders/subject"
        );
        document.getElementById("contactMessage").placeholder = translate(
            "contact/placeholders/message"
        );
        send = translate("contact/send");
        sent = translate("contact/sent");
        sending = translate("contact/sending");
        failed = translate("contact/failed");
    });

    const formValues = {
        name: "",
        emailAdress: "",
        subject: "",
        message: ""
    };
    $: buttonMessage = send;
    let buttonDisabled = false;
    let buttonStatus = 0;

    async function buttonSending() {
        await new Promise((r) => setTimeout(r, 500));
        buttonMessage = sending + ".";
        await new Promise((r) => setTimeout(r, 500));
        buttonMessage = sending + "..";
        await new Promise((r) => setTimeout(r, 500));
        buttonMessage = sending + "...";
        await new Promise((r) => setTimeout(r, 500));
    }

    async function sendEmail() {
        const buttonPromiseSending = buttonSending();
        const fetchData = await fetch("https://mailbackend.proso.se/mail", {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(formValues)
        }).catch((err) => {
            return new Response(
                JSON.stringify({
                    code: 400,
                    message: `${err}`
                }),
                { status: 400, statusText: `${err}` }
            );
        });
        await buttonPromiseSending;

        if (fetchData.status === 200) {
            buttonStatus = 2;
            formValues.message = "";
            formValues.subject = "";
            buttonMessage = sent;
        } else {
            buttonStatus = 3;
            buttonMessage = failed;
        }
        await new Promise((r) => setTimeout(r, 3000));
        buttonStatus = 0;
        buttonMessage = send;
        buttonDisabled = false;
    }

    const onSubmit = (e) => {
        buttonDisabled = true;
        buttonStatus = 1;
        buttonMessage = sending;
        sendEmail();
    };
</script>

<div class="text-center text-light" id="contact">
    <!-- svelte-ignore a11y-missing-content -->
    <h1 id="contactHeader" class="font-weight-bold" />
    <p id="contactBody" class="mb-5" />
    <form on:submit|preventDefault={onSubmit}>
        <div class="row">
            <div class="col-sm mb-3">
                <input
                    id="contactName"
                    class="form-control"
                    bind:value={formValues.name}
                    required
                />
            </div>
            <div class="col-sm mb-3">
                <input
                    id="contactEmail"
                    type="email"
                    class="form-control"
                    bind:value={formValues.emailAdress}
                    required
                />
            </div>
        </div>
        <div class="mb-3">
            <input
                id="contactSubject"
                class="form-control"
                bind:value={formValues.subject}
                required
            />
        </div>
        <div class="pb-3">
            <textarea
                id="contactMessage"
                class="form-control"
                rows="7"
                bind:value={formValues.message}
                required
            />
        </div>
        <button
            type="submit"
            class:buttonColorSending={buttonStatus === 1}
            class:buttonColorSent={buttonStatus === 2}
            class:buttonColorfailed={buttonStatus === 3}
            disabled={buttonDisabled}
            class="buttonClass btn btn-primary btn-lg mb-3 px-4"
            >{buttonMessage}</button
        >
    </form>
    <Divider />
</div>

<style>
    .buttonClass {
        opacity: 1 !important;
        box-shadow: none !important;
    }
    .buttonColorSending {
        background-color: #ffdd30 !important;
        border-color: #ffdd30 !important;
    }
    .buttonColorSent {
        background-color: #3ada2c !important;
        border-color: #3ada2c !important ;
    }
    .buttonColorfailed {
        background-color: #ff2100 !important;
        border-color: #ff2100 !important ;
    }
</style>
