<script>
    import { onMount } from "svelte";
    import Hamburger from "../AnimatedImages/svg/hamburger_svg.svelte";
    import { locale, subscribe, translate } from "../localization/Localize";
    import { currentLanguage } from "../localization/store.js";

    subscribe(() => {
        document.getElementById("navbarHome").innerText =
            translate("navbar/home");
        document.getElementById("navbarTechnologies").innerText = translate(
            "navbar/technologies"
        );
        document.getElementById("navbarWorkflow").innerText =
            translate("navbar/workflow");
        document.getElementById("navbarContact").innerText =
            translate("navbar/contact");
        document.getElementById("navbarAbout").innerText =
            translate("navbar/about");
    });

    // sidebar
    let sidebar = false;
    let swipeStart = null;
    let currentId = "home";
    const linkClicked = () => {
        setTimeout(() => {
            sidebar = false;
        }, 500);
    };
    const navIds = [
        { nav: "navbarHome", section: "home" },
        { nav: "navbarTechnologies", section: "technologies" },
        { nav: "navbarWorkflow", section: "workflow" },
        { nav: "navbarContact", section: "contact" },
        { nav: "navbarAbout", section: "about" }
    ];

    // sidebar dot
    const dotHeight = 1;
    let dotOffset;
    onMount(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    currentId = entry.isIntersecting
                        ? entry.target.id
                        : currentId;
                });
                dotOffset =
                    14.25 +
                    navIds.findIndex((ids) => ids.section === currentId) * 19;
            },
            { threshold: 0.2 }
        );

        navIds.forEach((ids) =>
            observer.observe(document.getElementById(ids.section))
        );
        // Navbar swiping
        function sidebarSwipeStart(event) {
            swipeStart =
                event.touches.length === 1
                    ? event.touches.item(0).clientX
                    : null;
        }
        function sidebarSwipeMove(event) {
            const swipeLength = 200;
            if (swipeStart) {
                const end = event.changedTouches.item(0).clientX;
                if (end < swipeStart - swipeLength) sidebar = true;
                else if (end > swipeStart + swipeLength) sidebar = false;
            }
        }

        window.addEventListener("touchstart", sidebarSwipeStart);
        window.addEventListener("touchmove", sidebarSwipeMove);

        return () => {
            navIds.forEach((ids) =>
                observer.unobserve(document.getElementById(ids.section))
            );

            window.removeEventListener("touchstart", sidebarSwipeStart);
            window.removeEventListener("touchmove", sidebarSwipeMove);
        };
    });
</script>

<div class:sidebarOpen={sidebar} class="sidebar" id="navbarNav">
    <div
        class="vw-100 vh-100"
        style="background: rgba(16, 16, 16, 0.5);"
        on:click={() => {
            sidebar = false;
        }}
    />
    <div
        class="position-absolute top-0 end-0 sidebarWidth"
        style="background: rgba(58, 58, 58, 0.8);"
    >
        <div
            class="w-100 d-grid pb-0"
            style="grid-template-columns: 1fr 1fr; overflow: hidden;"
        >
            <div
                class:bg-proso={$currentLanguage === "sv"}
                class="nav-item pb-1 rounded-0"
            >
                <p
                    class:text-white={$currentLanguage === "sv"}
                    class="text-center mb-0"
                    style="color:#999; cursor: pointer;"
                    on:click={() => {
                        locale("sv");
                    }}
                >
                    Sv
                </p>
            </div>
            <div
                class:bg-proso={$currentLanguage === "en"}
                class="nav-item pb-1 rounded-0"
            >
                <p
                    class:text-white={$currentLanguage === "en"}
                    class="text-center mb-0"
                    style="color:#999; cursor: pointer;"
                    on:click={() => {
                        locale("en");
                    }}
                >
                    En
                </p>
            </div>
        </div>
        <div class="me-2 d-grid" style="grid-template-columns: 1fr auto;">
            <ul class="navbar-nav text-end flex-column">
                <li class="text-end me-2">
                    <svg
                        on:click={() => {
                            sidebar = false;
                        }}
                        class="closeButton"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g style="stroke-width: 2; stroke-linecap: round;">
                            <line x1="1" y1="1" x2="24" y2="24" />
                            <line x1="1" y1="24" x2="24" y2="1" />
                        </g>
                    </svg>
                </li>
                {#each navIds as { nav, section }}
                    <li class="nav-item pe-auto sidebarLink">
                        <a
                            on:click={linkClicked}
                            id={nav}
                            class="nav-link me-3"
                            style="color: #BBB;"
                            href="/#{section}">{section}</a
                        >
                    </li>
                {/each}
            </ul>
            <div class="mt-3" style="margin-left: -0.25rem;">
                <div class="navbarBlob" style={`height: ${dotOffset}%;`} />
                <div
                    class="navbarBlob"
                    style={`height: ${dotHeight}%; background: #31A8FF; border-radius: 50px;`}
                />
            </div>
        </div>
    </div>
</div>
<nav class="fixed-top navbar navbar-light pe-4 ps-4 pe-none vw-100">
    <!--<img class="navbar-brand" src="images/logo.svg" alt="Proso logo" width=15% />-->
    <button
        class:pe-none={sidebar}
        class:navbar-toggler-clicked={sidebar}
        class="navbar-toggler pe-auto ms-auto mb-auto"
        style="margin-top: 0.375rem;"
        type="button"
        on:click={() => {
            sidebar = !sidebar;
        }}
    >
        <span>
            <Hamburger scale={2} open={sidebar} />
        </span>
    </button>
</nav>

<style>
    .closeButton {
        stroke: #bbb !important;
        cursor: pointer;
    }

    .closeButton:hover {
        stroke: #fff !important;
    }

    .sidebar {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1;
        transition: width 0.5s;
        width: 0;
        justify-content: end;
        height: 100vh;
    }

    .navbar-toggler {
        transition: margin-right 0.5s;
        transition-delay: 0.25s;
    }

    .navbar-toggler-clicked {
        margin-right: -120px !important;
    }

    .sidebarOpen {
        width: 100%;
    }

    .sidebarLink {
        margin-right: -0.5rem;
    }

    .navbarBlob {
        transition: height 0.5s;
    }

    .sidebarWidth {
        width: 25% !important;
        height: 100vh;
    }
    /* Bootstrap's "sm" */
    @media (max-width: 576px) {
        .sidebarWidth {
            width: 100% !important;
        }
    }
</style>
