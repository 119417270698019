<script>
    import { onMount } from "svelte";
    import { fade } from "svelte/transition";
    import { translate, subscribe } from "../localization/Localize.js";
    import Divider from "../components/Divider.svelte";

    let mounted = false;
    $: css = ["", "", ""];
    let handleMouseOver = (e) => {
        switch (e.target.innerText) {
            case "Website":
                css[0] = " transform: scale(1.1);";
                break;
            case "FullStack":
                css[1] = " transform: scale(1.1);";
                break;
            case "Application":
                css[2] = " transform: scale(1.1);";
                break;
            default:
                break;
        }
    };
    let handleMouseOut = (e) => {
        switch (e.target.innerText) {
            case "Website":
                css[0] = "";
                break;
            case "FullStack":
                css[1] = "";
                break;
            case "Application":
                css[2] = "";
                break;
            default:
                break;
        }
    };

    subscribe(() => {
        document.getElementById("headerText").innerHTML =
            translate("billboard/header");

        const textReplacements = [
            { id: "billboardBody", key: "billboard/body" },
            { id: "headerContact", key: "billboard/contact" },
            { id: "billboardHero1", key: "billboard/hero[0]" },
            { id: "billboardHero2", key: "billboard/hero[1]" },
            { id: "billboardHero3", key: "billboard/hero[2]" }
        ];

        for (let i = 0; i < textReplacements.length; i++)
            document.getElementById(textReplacements[i].id).innerText =
                translate(textReplacements[i].key);
    });

    onMount(() => {
        mounted = true;
    });
</script>

<div id="home">
    <div class="text-center text-sm-start">
        <h1
            class="mb-4"
            style="font-size: calc(4rem + 1vw); font-family: 'Times New Roman', Times, serif;"
        >
            Proso
        </h1>
        <!-- svelte-ignore a11y-missing-content -->
        <h2 id="headerText" class="text-white mb-1" />
        <p id="billboardBody" style="color: #A0A0A0;" class="mb-2" />
        <!-- svelte-ignore a11y-missing-content -->
        <a id="headerContact" href="/#contact" class="btn btn-primary" />
    </div>
    {#if mounted}
        <div
            class="d-grid text-center text-proso my-5"
            style="grid-template-columns: 1fr 1fr 1fr; background: rgba(54, 54, 54, 0.25); border-radius: 15px;"
        >
            <a href="/#technologies">
                <div
                    style={css[0]}
                    class="cubeContainer m-auto p-4"
                    transition:fade={{ delay: 250, duration: 500 }}
                >
                    <img
                        style="width: 100%; min-width: 10vw"
                        alt="Website"
                        src="images/iconify/ic_baseline-web.svg"
                    />
                </div>
            </a>
            <a href="/#technologies">
                <div
                    style={css[1]}
                    class="cubeContainer m-auto p-4"
                    transition:fade={{ delay: 500, duration: 500 }}
                >
                    <img
                        style="width: 100%; min-width: 10vw"
                        alt="FullStack"
                        src="images/iconify/gg_server.svg"
                    />
                </div>
            </a>
            <a href="/#technologies">
                <div
                    style={css[2]}
                    class="cubeContainer m-auto p-4"
                    transition:fade={{ delay: 750, duration: 500 }}
                >
                    <img
                        style="width: 100%; min-width: 10vw"
                        alt="Application"
                        src="images/iconify/mdi_application-brackets-outline.svg"
                    />
                </div>
            </a>
            {#each [{ delay: 250, duration: 500 }, { delay: 500, duration: 500 }, { delay: 750, duration: 500 }] as { delay, duration }, i}
                <!-- svelte-ignore a11y-missing-content -->
                <div
                    on:mouseover={handleMouseOver}
                    on:focus={handleMouseOver}
                    on:mouseout={handleMouseOut}
                    on:blur={handleMouseOut}
                    class="grid-item"
                    style="cursor: pointer;"
                    transition:fade={{ delay: delay, duration: duration }}
                >
                    <a
                        style="color:inherit; text-decoration:inherit;"
                        href="/#technologies"
                        ><p
                            style="font-size: calc(0.5rem + 1.5vw);"
                            id={"billboardHero" + (i + 1)}
                        /></a
                    >
                </div>
            {/each}
        </div>
    {/if}
    <Divider />
</div>

<style>
    .cubeContainer {
        transition: transform 0.25s ease-in-out;
        cursor: pointer;
    }

    .cubeContainer:hover {
        transform: scale(1.1);
    }
</style>
