<script>
    export let open = false;
    export let scale = 1;
</script>

<svg
    style={`transform: scale(${scale});`}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g style="stroke: #FFFFFF; stroke-width: 2; stroke-linecap: round;">
        <line
            class:TopClose={open}
            class:TopOpen={!open}
            x1="5"
            y1="23"
            x2="25"
            y2="23"
        />
        <line
            class:MiddleClose={open}
            class:MiddleOpen={!open}
            x1="5"
            y1="15"
            x2="25"
            y2="15"
        />
        <line
            class:BottomClose={open}
            class:BottomOpen={!open}
            x1="5"
            y1="7"
            x2="25"
            y2="7"
        />
    </g>
</svg>

<style>
    @keyframes TopClose {
        from {
            transform: translateY(0%);
            stroke-opacity: 0.55;
        }
        to {
            transform: translateY(-27%);
            stroke-opacity: 0;
        }
    }

    @keyframes MiddleClose {
        from {
            stroke-opacity: 0.55;
        }
        to {
            stroke-opacity: 0;
        }
    }

    @keyframes BottomClose {
        from {
            transform: translateY(0%);
            stroke-opacity: 0.55;
        }
        to {
            transform: translateY(27%);
            stroke-opacity: 0;
        }
    }

    .TopClose {
        animation: 0.25s ease 0s normal both TopClose;
    }

    .MiddleClose {
        animation: 0.25s ease 0.25s normal both MiddleClose;
    }

    .BottomClose {
        animation: 0.25s ease 0s normal both BottomClose;
    }

    @keyframes TopOpen {
        from {
            transform: translateY(-27%);
            stroke-opacity: 0;
        }
        to {
            transform: translateY(0%);
            stroke-opacity: 0.55;
        }
    }

    @keyframes MiddleOpen {
        from {
            stroke-opacity: 0;
        }
        to {
            stroke-opacity: 0.55;
        }
    }

    @keyframes BottomOpen {
        from {
            transform: translateY(27%);
            stroke-opacity: 0;
        }
        to {
            transform: translateY(0%);
            stroke-opacity: 0.55;
        }
    }

    .TopOpen {
        animation: 0.25s ease 0.75s normal both TopOpen;
    }

    .MiddleOpen {
        animation: 0.25s ease 0.375s normal both MiddleOpen;
    }

    .BottomOpen {
        animation: 0.25s ease 0.75s normal both BottomOpen;
    }
</style>
