import App from "./App.svelte";
import "@popperjs/core";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

const app = new App({
    target: document.body
});

export default app;
