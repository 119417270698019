<script>
    import { onMount } from "svelte";
    import { subscribe, translate } from "../localization/Localize";
    import Divider from "../components/Divider.svelte";

    let start = undefined;
    const duration = 30000;
    let technology_percentage = 0;
    let selected_technology = 0;

    let paused = false;
    let elapsed_time_at_pause = 0;
    const pause_duration = duration * 3;
    let pause_timestamp = undefined;

    let technologyCarouselData = [{ header: "", body: "" }];
    let clientHeightCompanyLogo = undefined;
    let clientHeightNavbarText = undefined;

    const imageSources = [
        [
            { src: "/images/CompanyLogos/Frontend/Svelte.svg", alt: "Svelte" },
            { src: "/images/CompanyLogos/Frontend/Jquery.svg", alt: "Jquery" },
            { src: "/images/CompanyLogos/Frontend/React.svg", alt: "React" }
        ],
        [
            { src: "/images/CompanyLogos/Backend/Nodejs.svg", alt: "NodeJS" },
            { src: "/images/CompanyLogos/Backend/Spring.svg", alt: "Spring" }
        ],
        [
            { src: "/images/CompanyLogos/Database/Oracle.svg", alt: "Oracle" },
            {
                src: "/images/CompanyLogos/Database/Microsoftsql.svg",
                alt: "Microsoft SQL"
            },
            {
                src: "/images/CompanyLogos/Database/Postrgres.svg",
                alt: "Postgres"
            }
        ]
    ];

    subscribe(() => {
        document.getElementById("technologiesHeader").innerText = translate(
            "technologies/header"
        );
        document.getElementById("technologiesBody").innerText =
            translate("technologies/body");

        technologyCarouselData = translate("technologies/carousel");
    });

    onMount(() => {
        let frame;
        start = new Date();

        function loop() {
            frame = requestAnimationFrame(loop);

            if (paused) {
                if (new Date() - pause_timestamp >= pause_duration) {
                    paused = false;
                    start = new Date();
                    start.setTime(start.getTime() - elapsed_time_at_pause);
                }
            } else {
                const elapsed = new Date() - start;
                technology_percentage =
                    Math.max(0, Math.min(elapsed / duration, duration)) * 100;

                if (elapsed >= duration) {
                    selected_technology =
                        (selected_technology + 1) %
                        technologyCarouselData.length;
                    start = new Date();
                }
            }
        }

        loop();
        return () => cancelAnimationFrame(frame);
    });

    const pause = (index) => {
        paused = true;
        pause_timestamp = new Date();

        if (selected_technology === index)
            elapsed_time_at_pause = new Date() - start;
        else {
            elapsed_time_at_pause = new Date();
            technology_percentage = 100;
        }

        selected_technology = index;
    };
</script>

<div class="text-center" id="technologies">
    <div class="mb-5">
        <h1 id="technologiesHeader">Technologies</h1>
        <p id="technologiesBody" class="mx-auto w-100" style="color: #BBB;">
            As a consulting firm the technologies we use depend on each project.
            Everything from fullstack to Application Development.
        </p>
    </div>
    <div id="technologyContainer">
        <div class="row mx-2 pt-4 pb-2">
            <div class="col-auto p-0">
                <h3
                    style="color: #31A8FF;"
                    class="text-start"
                    bind:clientHeight={clientHeightCompanyLogo}
                >
                    {technologyCarouselData[selected_technology].header}
                </h3>
            </div>
            <div class="col p-0" style="height: {clientHeightCompanyLogo}px">
                {#each imageSources[selected_technology] as { src, alt }}
                    <img
                        class="me-1 float-end"
                        height="100%"
                        width="auto"
                        {src}
                        {alt}
                    />
                {/each}
            </div>
        </div>
        <p class="pb-3 mx-2 " style="color: #DDDDDD;">
            {technologyCarouselData[selected_technology].body}
        </p>
    </div>
    <div class="d-flex justify-content-center">
        {#each technologyCarouselData as { header }, i}
            <div
                class="m-2"
                style="cursor: pointer;"
                on:click={() => {
                    pause(i);
                }}
            >
                <p
                    class="gradientText text-start mb-1"
                    bind:clientHeight={clientHeightNavbarText}
                    style="background-image: linear-gradient(to right, #31A8FF {selected_technology ===
                    i
                        ? technology_percentage
                        : 0}%, white {selected_technology === i
                        ? technology_percentage
                        : 0}%)"
                >
                    {header}
                </p>
                <div class="d-flex justify-content-center">
                    <div
                        style="height: {clientHeightNavbarText /
                            2}px; width:{clientHeightNavbarText /
                            2}px; border-radius: 100% 100%; background: {selected_technology ===
                        i
                            ? '#31A8FF'
                            : 'white'} "
                    />
                </div>
            </div>
        {/each}
    </div>
    <Divider />
</div>

<style>
    .gradientText {
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }

    #technologyContainer {
        background-color: rgba(36, 36, 36, 25%);
        border-radius: 15px;
    }
</style>
