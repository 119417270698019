<script>
    import Navbar from "./main page/Navbar.svelte";
    import Billboard from "./main page/Billboard.svelte";
    import Technologies from "./main page/Technologies.svelte";
    import About from "./main page/About.svelte";
    import Workflow from "./main page/Workflow.svelte";
    import Contact from "./main page/Contact.svelte";
    import Footer from "./main page/Footer.svelte";

    import { onMount, tick } from "svelte";
    import { load, localize } from "./localization/Localize.js";
    import english from "./localization/languages/en.json";
    import swedish from "./localization/languages/sv.json";

    onMount(async () => {
        load([
            { name: "en", data: english },
            { name: "sv", data: swedish }
        ]);
        await tick();
        localize();
    });
</script>

<Navbar />
<main class="main container">
    <Billboard />
    <Technologies />
    <Workflow />
    <Contact />
    <About />
</main>
<Footer />

<style>
    .main {
        background-image: url("/images/background/BackgroundVector.svg");
        background-repeat: repeat-y;
    }
</style>
