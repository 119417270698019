<script>
    import { subscribe, translate } from "../localization/Localize";
    import Divider from "../components/Divider.svelte";

    subscribe(() => {
        document.getElementById("workflowHeader").innerText =
            translate("workflow/header");

        document.getElementById("workflowRequirementHeader").innerText =
            translate("workflow/Requirements/header");
        document.getElementById("workflowRequirementBody").innerText =
            translate("workflow/Requirements/body");

        document.getElementById("workflowPrototypeHeader").innerText =
            translate("workflow/Prototyping/header");
        document.getElementById("workflowPrototypeBody").innerText = translate(
            "workflow/Prototyping/body"
        );

        document.getElementById("workflowDevelopmentHeader").innerText =
            translate("workflow/Development/header");
        document.getElementById("workflowDevelopmentBody").innerText =
            translate("workflow/Development/body");

        document.getElementById("workflowValuesHeader").innerText = translate(
            "workflow/Values/header"
        );
        document.getElementById("workflowValuesBody").innerHTML = translate(
            "workflow/Values/body"
        );
    });
</script>

<div class="text-center" id="workflow">
    <!-- svelte-ignore a11y-missing-content -->
    <h1 id="workflowHeader" class="font-weight-bold" />

    <div class="row text-white">
        <div class="col-sm justify-content-center">
            <div class="d-flex flex-sm-column">
                <img
                    class="ImagePadding align-self-sm-center"
                    src="/images/iconify/Meeting.svg"
                    alt="Meeting"
                />
                <div>
                    <!-- svelte-ignore a11y-missing-content -->
                    <h5 id="workflowRequirementHeader" class="pt-1" />
                    <p id="workflowRequirementBody" />
                </div>
            </div>
        </div>
        <div class="col-sm justify-content-center">
            <div id="colrowreverse" class="d-flex flex-sm-column">
                <img
                    class="ImagePadding align-self-sm-center"
                    src="/images/iconify/ic_baseline-web-150.svg"
                    alt="Meeting"
                />
                <div>
                    <!-- svelte-ignore a11y-missing-content -->
                    <h5 id="workflowPrototypeHeader" class="pt-1" />
                    <p id="workflowPrototypeBody" />
                </div>
            </div>
        </div>
        <div class="col-sm justify-content-center">
            <div class="d-flex flex-sm-column">
                <img
                    class="ImagePadding align-self-sm-center"
                    src="/images/iconify/application-loading.svg"
                    alt="Meeting"
                />
                <div>
                    <!-- svelte-ignore a11y-missing-content -->
                    <h5 id="workflowDevelopmentHeader" class="pt-1" />
                    <p id="workflowDevelopmentBody" />
                </div>
            </div>
        </div>
    </div>

    <div
        class="mt-2 mx-auto justify-content-center text-white"
        style="width: 75%"
    >
        <img
            src="/images/You.svg"
            style="width: 75%; max-width: 25vw;"
            alt="Meeting"
        />
        <!-- svelte-ignore a11y-missing-content -->
        <h5 id="workflowValuesHeader" class="pt-1" />
        <p id="workflowValuesBody" />
    </div>
    <Divider />
</div>

<style>
    p {
        font-size: 0.75rem;
    }

    .ImagePadding {
        width: 50%;
        max-width: 25vw;
    }

    @media (max-width: 575px) {
        #colrowreverse {
            flex-direction: row-reverse;
        }

        img {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
            width: 30%;
        }

        p,
        h5 {
            padding-right: 1rem !important;
            padding-left: 1rem !important;
        }
        .ImagePadding {
            padding-bottom: 2rem;
        }
    }
</style>
