<script>
    import { subscribe, translate } from "../localization/Localize";

    subscribe(() => {
        document.getElementById("aboutHeader").innerText =
            translate("about/header");

        document.getElementById("aboutImg1").alt = translate(
            "about/employees/dean/alt"
        );
        document.getElementById("aboutName1").innerText = translate(
            "about/employees/dean/name"
        );
        document.getElementById("aboutRole1").innerText = translate(
            "about/employees/dean/role"
        );
        document.getElementById("aboutAbout1").innerText = translate(
            "about/employees/dean/about"
        );

        document.getElementById("aboutImg2").alt = translate(
            "about/employees/simon/alt"
        );
        document.getElementById("aboutName2").innerText = translate(
            "about/employees/simon/name"
        );
        document.getElementById("aboutRole2").innerText = translate(
            "about/employees/simon/role"
        );
        document.getElementById("aboutAbout2").innerText = translate(
            "about/employees/simon/about"
        );

        document.getElementById("aboutBody1").innerText =
            translate("about/body1");
        document.getElementById("aboutBody2").innerText =
            translate("about/body2");
    });
</script>

<div class="text-center" id="about">
    <!-- svelte-ignore a11y-missing-content -->
    <h1 id="aboutHeader" class="font-weight-bold big-font text-proso" />

    <div class="row text-center text-white m-0">
        <div class="col-sm-6 px-2 mt-2">
            <img
                id="aboutImg1"
                class="Image"
                src="images/Dean.png"
                alt="Dean"
            />
            <div
                id="aboutName1"
                class="text-proso fw-bold text-nowrap"
                style="font-size: 1.15rem;"
            />
            <div id="aboutRole1" class="mt-1" style="font-size: 0.7rem;" />
            <div
                id="aboutAbout1"
                class="text-secondary"
                style="font-size: 0.55rem;"
            />
        </div>
        <div class="col-sm-6 px-2 mt-2">
            <img
                id="aboutImg2"
                class="Image"
                src="images/Simon.png"
                alt="Simon"
            />
            <div
                id="aboutName2"
                class="text-proso fw-bold text-nowrap"
                style="font-size: 1.15rem;"
            />
            <div id="aboutRole2" class="mt-1" style="font-size: 0.7rem;" />
            <div
                id="aboutAbout2"
                class="text-secondary"
                style="font-size: 0.55rem;"
            />
        </div>
        <div
            class="mt-5 p-3 mb-4"
            style="font-size: 0.8rem; background: rgba(54, 54, 54, 0.25); border-radius: 15px;"
        >
            <div class="row">
                <div class="col-sm align-self-center">
                    <img
                        class="columning"
                        src="/images/iconify/briefcase.svg"
                        alt="briefcase"
                    />
                </div>
                <div class="col-sm-10">
                    <p id="aboutBody1" class="text-start mb-0" />
                </div>
            </div>
            <br />
            <div class="row flex-sm-row-reverse">
                <div class="col-sm-2 align-self-center">
                    <img
                        class="columning"
                        src="/images/iconify/heart.svg"
                        alt="heart"
                    />
                </div>
                <div class="col-sm-10">
                    <p id="aboutBody2" class="text-start mb-0" />
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .Image {
        border-radius: 100%;
        width: 15vw;
        max-width: 25vw;
    }

    @media (max-width: 575px) {
        .columning {
            padding: 1rem !important;
            width: 100%;
            max-width: 30vw;
        }
        .Image {
            min-width: 50vw;
        }
    }
</style>
